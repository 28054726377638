/* eslint-disable new-cap */
import EventEmitter from 'wolfy87-eventemitter';

/**
 * Call Dialog's Campaign URL LeadApp manager
 */
var campaignURLHandler = {
    // DATA

    // phonenumber that is used when we are not using the number of the contact.
    // For example when changing the number in the contact card.
    phonenumber: null,

    // current campaign URL
    campaignUrls: null,

    // current contact data
    contactData: {},

    EVENTS: {
        LOADED: 'campaign_dialog_loaded',
    },

    FIRST_FRAME_ID: 'camp_frame_1',

    skipNextReload: false,

    /// FUNCTIONS
    reset: function() {
        this.campaignUrls = null;
        this.contactData = {};
        this.phonenumber = null;
    },

    setSkipNextReload: function(bool) {
        this.skipNextReload = bool;
    },

    getContactVariables: function() {
        var contactVars = {
            '{ld_name}': lang('Name'),
            '{ld_fname}': lang('Etunimi'),
            '{ld_lname}': lang('Sukunimi'),
            '{ld_phone}': lang('_phone_number_'),
            '{ld_address}': lang('label_street_address'),
            '{ld_city}': lang('_city_'),
            '{ld_country}': lang('_country_'),
            '{ld_office}': lang('office_one'),
            '{ld_vatin}': lang('vat_identification'),
            '{ld_postal}': lang('template_opt_contact_postal'),
            '{ld_ssc}': lang('template_opt_contact_ssc'),
            '{ld_title}': lang('template_opt_contact_title'),
            '{ld_www}': lang('template_opt_contact_www'),
            '{ld_email}': lang('template_opt_contact_email'),
            '{ld_year_of_birth}': lang('template_opt_contact_birth'),
            '{ld_gender}': lang('template_opt_contact_gender'),
        };
        for (var i = 1; i <= 35; i++) contactVars['{ld_other_info_' + i + '}'] = lang('other_info_undef_label_' + i);
        return contactVars;
    },

    getCallinfoVariables: function() {
        var callinfoVars = {
            '{ld_agent_id}': lang('template_opt_agent_id'),
            '{ld_agent_name}': lang('template_opt_agent'),
            '{ld_agent_user_name}': lang('agent_username'),
            '{ld_agent_external_id}': lang('agent_external_id'),
            '{ld_callid}': lang('call_id'),
            '{ld_campaign_id}': lang('campaign_id'),
            '{ld_callinglist_id}': lang('calling_list_id'),
            '{ld_active_session_id}': lang('agent_active_session_id'),
            '{ld_login_time}': lang('agent_login_time'),
            '{ld_client_auth}': lang('label_client_auth'),
            '{ld_client_id}': lang('admin.campaign_editor.optional_settings.url_variable.client_id.menu_item'),
            '{ld_callback_id}': lang('callback_id'),
            '{ld_callback_comment}': lang('Callback-comment'),
            '{ld_contact_id}': lang('contact_id'),
            '{ld_inbound_queue_id}': lang('inbound_queue_id'),
            '{ld_origin}': lang('calldialog_origin'),
            '{ld_email_id}': lang('admin.campaign_editor.optional_settings.url_variable.email_id.menu_item'),
            '{jwt}': lang('admin.campaign_editor.optional_settings.url_variable.jwt.menu_item'),
        };
        return callinfoVars;
    },

    /**
     * Load contact from server
     * @param {int} id contact id
     */
    loadContactData: function(id) {
        DEBUG('campaignURLHandler.loadContactData: ' + id);
        var self = this;
        OrderContact.createFromDB({
            id: id,
            showWait: false,
            success: function(contact) {
                self.setContactData(contact);
                self.loadUrls();
            },
            error: function() {
                // use empty contact on error
                var c = new OrderContact();
                self.setContactData(c);
                self.loadUrls();
            },
        });
    },

    /**
     * Set contact details
     * @param {OrderContact} contact
     */
    setContactData: function(contact) {
        DEBUG('campaignURLHandler.setContactData: ' + contact);
        var callback_comment = $('#callback-prev-comment').val();
        this.contactData = {
            ld_address: contact.data.address || '',
            ld_city: contact.data.city || '',
            ld_country: contact.data.country || '',
            ld_office: contact.data.office || '',
            ld_vatin: contact.data.vatin || '',
            ld_postal: contact.data.postal || '',
            ld_ssc: contact.data.ssc || '',
            ld_title: contact.data.title || '',
            ld_www: contact.data.www || '',
            ld_year_of_birth: contact.data.year_of_birth || '',
            ld_gender: contact.data.gender || '',
            ld_phone: contact.data.phone || '',
            ld_email: contact.data.email || '',
            ld_name: contact.fullName(),
            ld_fname: contact.data.fname || '',
            ld_lname: contact.data.lname || '',
            ld_callid: CallDialog.CallId || '',
            ld_agent_id: UserInfo.id || '',
            ld_agent_user_name: UserInfo.name || '',
            ld_agent_name: UserInfo.full_name || '',
            ld_agent_external_id: UserInfo.external_id || '',
            ld_campaign_id: CallDialog.CampaignId || '',
            ld_callinglist_id: CallDialog.CallinglistId || '',
            ld_active_session_id: UserInfo.active_session_id || '',
            ld_login_time: UserInfo.login_time || '',
            ld_client_auth: UserInfo.auth || '',
            ld_client_id: UserInfo.client || '',
            ld_callback_id: CallDialog.CallbackId || '',
            ld_callback_comment: CallDialog.CallbackComment || (callback_comment || ''),
            ld_contact_id: contact.contact_id || '',
            ld_inbound_queue_id: (ActiveCall && ActiveCall.getInboundQueueId()) || '',
            ld_origin: CallDialog.origin || '',
            ld_email_id: typeof Multichannel !== 'undefined' && Multichannel.Email.MessageUi.Controller.emailId() || ''
        };

        if (this.phonenumber) {
            this.contactData.ld_phone = this.phonenumber;
        }

        for (var i = 0; i < contact.data.other_info.length; i++) {
            var other_info_tmp = 'ld_other_info_' + (i + 1);
            this.contactData[other_info_tmp] = contact.data.other_info[i].value || '';
        }
    },

    init: function () {
        //Display notification for user on url campaign tabs to refresh if existing on the event CONTACT_SAVED
        CallDialog.on(CallDialog.EVENTS.CONTACT_SAVED, function () {
            $('.reload-campaign-url-div').css('display', 'flex');
        });

        CallDialog.on(CallDialog.EVENTS.COMPANY_MODIFIED, function () {
            $('.reload-campaign-url-div').css('display', 'flex');
        });
    },

    /**
     *
     * @param {CallData} argument
     * @param {array} campaign_urls
     */
    loadCampaignURLs: function(argument, campaign_urls) {
        if (this.skipNextReload) {
            return;
        }

        DEBUG('campaignURLHandler.loadCampaignURLs: ' + argument + ', ' + JSON.stringify(campaign_urls, null, 4));
        if (campaign_urls == null) return;
        this.reset();
        // select contact id
        var id = null;
        if (argument != null && typeof argument == 'object') id = argument.contact_id;
        else if (argument != null) id = argument;

        this.campaignUrls = campaign_urls;
        if (id != null && id != 0) {
            // The argument contains the phonenumber from the contact card.
            if (typeof argument.phonenumber !== 'undefined' && !isNaN(argument.phonenumber)) {
                this.phonenumber = argument.phonenumber;
            }
            this.loadContactData(id);
        } else {
            // Create empty contact and load URL (used by create order without call)
            var contact = new OrderContact();
            this.loadContactURLs(contact, campaign_urls);
        }
    },

    closeBanner: function(frameUrlsIndex) {
        $('#close-campaign-url-' + frameUrlsIndex).parent().parent().css('display', 'none');
    },

    reloadUrl: function (frameUrlsIndex) {
        var self = this;
        OrderContact.createFromDB({
            id: CallDialog.ContactId,
            showWait: false,
            success: function (contact) {
                self.reloadUrlHelper(contact, frameUrlsIndex);
            },
            error: function () {
                ShowError(lang('error_retrieving_contact_information'));
            }
        });
    },

    reloadUrlHelper: function (contact, frameUrlsIndex) {
        var self = this;

        self.setContactData(contact);
        $('#reload-campaign-url-' + frameUrlsIndex).parent().parent().css('display', 'none');

        var campaignUrlsToReload = this.campaignUrls[frameUrlsIndex];

        // Check which URLs can be used immediately without signing
        if (campaignUrlsToReload.indexOf('{jwt}') !== -1) {
            $.ajax({
                url: '?page=ajax_controller&module=Signature&cmd=campaign_urls',
                type: 'POST',
                dataType: 'json',
                data: {
                    campaign: CallDialog.CampaignId,
                    data: this.contactData,
                }
            }).then(function (signedUrls) {
                self.setTabUrl(frameUrlsIndex, signedUrls[frameUrlsIndex]);
            });
        } else {
            self.setTabUrl(frameUrlsIndex, self.parseURL(campaignUrlsToReload));
        }
    },

    /**
     * Reload campaign frames with contact object
     * @param {OrderContact} contact
     * @param {array} campaign_urls
     * @return {Promise}
     */
    loadContactURLs: function(contact, campaign_urls) {
        DEBUG('campaignURLHandler.loadContactURLs: ' + contact + ', ' + JSON.stringify(campaign_urls, null, 4));
        if (campaign_urls == null) {
            return $.Deferred()
                .resolve()
                .promise();
        }

        this.reset();
        this.campaignUrls = campaign_urls;
        this.setContactData(contact);
        return this.loadUrls();
    },

    /**
     * Load all campaign frames into call dialog
     *
     * @return {Promise}
     */
    loadUrls: function() {
        DEBUG('campaignURLHandler.loadURLs: ' + JSON.stringify(this.campaignUrls, null, 4));
        var deferred = $.Deferred();
        if (this.campaignUrls) {
            var self = this;

            // Check which URLs can be used immediately without signing
            var needSignatureIndex = [];
            this.campaignUrls.forEach(function(value, index) {
                if (value.indexOf('{jwt}') !== -1) {
                    needSignatureIndex.push(index);
                } else {
                    self.setTabUrl(index, self.parseURL(value));
                }
            });

            if (needSignatureIndex.length > 0) {
                // Request signed campaign URLs
                $.ajax({
                    url: '?page=ajax_controller&module=Signature&cmd=campaign_urls',
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        campaign: CallDialog.CampaignId,
                        data: this.contactData,
                    },
                })
                    .then(function(signedUrls) {
                        needSignatureIndex.forEach(function(index) {
                            self.setTabUrl(index, signedUrls[index]);
                        });
                    })
                    .always(function() {
                        deferred.resolve();
                    });
            } else {
                deferred.resolve();
            }
        } else {
            deferred.resolve();
        }
        return deferred.promise();
    },

    /**
     * Set call dialog campaign URL tab's iframe source
     *
     * @param {number} tabIndex index of the tab in call dialog (starting from 0)
     * @param {string} url iframe URL
     */
    setTabUrl: function(tabIndex, url) {
        var jqElem = $('#camp_frame_' + (tabIndex + 1).toString());
        if (jqElem.length > 0) {
            jqElem.attr('src', url ? url : 'about:blank');
            campaignURLHandler.emit(campaignURLHandler.EVENTS.LOADED, jqElem);
        }
    },

    parseURL: function(url) {
        DEBUG('campaignURLHandler.parseURL: ' + url);
        return this.replaceURLVars(this.contactData, url);
    },

    replaceURLVars: function(variable_set, url) {
        DEBUG('campaignURLHandler.replaceURLVars: ' + variable_set + ', ' + url);
        if (typeof url == 'string') {
            $.each(variable_set, function(key, value) {
                if (typeof key != 'undefined' && key != null) {
                    var regexp = new RegExp('{' + key + '}', 'g');
                    url = url.replace(regexp, encodeURIComponent(value));
                }
            });
            return url;
        } else {
            return '';
        }
    },

    /**
     * Request the full window campaign URL to be opened. Request will be served once the call dialog is opened.
     */
    requestFullWindow: function() {
        if (this.campaignUrls.length === 1) {
            if (CallDialog.isVisible()) {
                this.serveFullWindowRequest();
            } else {
                CallDialog.once(CallDialog.EVENTS.OPENED, this.serveFullWindowRequest.bind(this));
            }
        }
    },

    /**
     * Serve the full window request if there is an active call ongoing. We don't want to open the full window for old calls.
     */
    serveFullWindowRequest: function() {
        this.openFullWindow();
    },

    /**
     * Opens the full window campaign URL
     */
    openFullWindow: function() {
        CallDialog.enableFullWindowCampaignUrlSupport();
        $('#' + this.FIRST_FRAME_ID).addClass('full_window_campaign_url');
        CallDialog.openTab('tab-camp-url-1');
    },

    /**
     * Closes the full window campaign URL and restores it under the regular campaign tab
     */
    closeFullWindow: function() {
        CallDialog.disableFullWindowCampaignUrlSupport();
        $('#' + this.FIRST_FRAME_ID).removeClass('full_window_campaign_url');
    },

    isFirstFrameVisible: function() {
        return $('#' + this.FIRST_FRAME_ID).is(':visible');
    },
};

$.extend(campaignURLHandler, EventEmitter.prototype);

export default campaignURLHandler;
